import React from "react"
import { graphql, StaticQuery } from "gatsby"
import OrderCakeOnlineTemplate from '../components/order-cake-online-template'


const OrderOnline = () => (
  <StaticQuery
    query={graphql`
      query OrderOnlineQuery {
        markdownRemark(
          frontmatter: { templateKey: { eq: "order-online" } }
        ) {
          id
          frontmatter {
            banner {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            rows {
              image {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              title
              description
              buttonURL
              buttonText
            }
            title
            seoTitle
            description
            metaDescription
          }
        }
      }
    `}
    render={data => {
      const imageData =
        data.markdownRemark.frontmatter.banner.childImageSharp.fluid
      const mData = data.markdownRemark.frontmatter
      return (
        <OrderCakeOnlineTemplate
          title={mData.title}
          seoTitle={mData.seoTitle}
          rows={mData.rows}
          banner={imageData}
          description={mData.description}
          metaDescription={mData.metaDescription}
        />
      )
    }}
  />
)

const OrderOnlinePage = () => {
  return <OrderOnline />
}

export default OrderOnlinePage
